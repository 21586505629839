import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Row, Col, Typography, Avatar } from 'antd';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

// Base URL for the icons
const ICON_BASE_URL = 'https://img.icons8.com/plasticine/100/';

const BenefitItem = ({ content, iconFileName }) => {
  const itemStyle = {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap', // Allow wrapping for small screens
    padding: '10px', // Add padding to prevent cuts
  };

  const textStyle = {
    marginLeft: '10px',
    fontSize: '15px',
    textAlign: 'left',
    flex: 1, // Allow text to take remaining space
    wordBreak: 'break-word', // Prevent long text from overflowing
  };

  const iconStyle = {
    flexShrink: 0, // Prevent the icon from shrinking on small screens
  };

  // Construct the full URL for the icon
  const iconUrl = `${ICON_BASE_URL}${iconFileName}`;

  return (
    <div style={itemStyle}>
      <Avatar
        src={iconUrl}
        size="large"
        shape="square"
        style={{ borderRadius: '8px', ...iconStyle }}
      />
      <Text style={textStyle}>
        <ReactMarkdown components={{ p: ({ node, ...props }) => <p {...props} /> }}>
          {content}
        </ReactMarkdown>
      </Text>
    </div>
  );
};

const FeatureBenefits = ({ featureKey }) => {
  const { t } = useTranslation();

  // Fetch benefits and icon file names dynamically from the locale file
  const benefits = [
    {
      text: t(`${featureKey}.fields.bullet1.text`),
      icon: t(`${featureKey}.fields.bullet1.icon`),
    },
    {
      text: t(`${featureKey}.fields.bullet2.text`),
      icon: t(`${featureKey}.fields.bullet2.icon`),
    },
    {
      text: t(`${featureKey}.fields.bullet3.text`),
      icon: t(`${featureKey}.fields.bullet3.icon`),
    },
  ];

  return (
    <Row gutter={[20, 0]}>
      {benefits.map((benefit, index) => (
        <Col xs={24} sm={12} md={8} key={index}> {/* Responsive column sizes */}
          <BenefitItem content={benefit.text} iconFileName={benefit.icon} />
        </Col>
      ))}
    </Row>
  );
};

export default FeatureBenefits;
