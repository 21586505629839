import authRequest from '../authRequest';
import { getCurrentUser, setCurrentUser } from '../userStorage';

const updateUserSurvey = async (fields) => {
  try {
    const userSurvey = Object.fromEntries(
      Object.entries(fields).filter(([key, value]) => value !== undefined)
    );

    const response = await authRequest(`/user_survey`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      data: { user_survey: userSurvey },
    });

    if (response.status !== 200) {
      throw new Error('Error updating survey');
    }

    // Update the locally stored user object with survey_completed: true
    const currentUser = getCurrentUser();
    const updatedUser = { ...currentUser, survey_completed: true };
    setCurrentUser(updatedUser);

    return response.data;
  } catch (error) {
    console.error('Error updating survey:', error);
    throw error;
  }
};

export default updateUserSurvey;