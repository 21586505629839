import { getCurrentUser } from './userStorage';

const supportedFeature = (featureName) => {
  switch (featureName) {
    case "Calls":
      return isCallsSupported();
    default:
      return false;
  }
};

// Private functions
const isCallsSupported = () => {
  const currentUser = getCurrentUser();
  // if calls_country isn't set yet, return true
  if (!currentUser.calls_country) {
    return false;
  }
  // Recorded Session test number: +1 256 888 9188
  const supportedCountries = ['AR', 'CL', 'CO', 'MX'];
  return supportedCountries.some((countryCode) =>
    currentUser.calls_country.includes(countryCode)
  );
};

export default supportedFeature;