import React from 'react';
import {Form, Input} from 'antd';
import {LockOutlined} from "@ant-design/icons";
import { useTranslation } from 'react-i18next';

const PasswordInput = ({label}) => {
    const { t } = useTranslation();
    const passwordLabel = label || t('common.fields.password');

    return (
        <Form.Item
            name="password"
            rules={[
                { required: true, message: t('common.errors.password_required') }
            ]}
            validateTrigger={['onBlur', 'onSubmit']} // Trigger validation on blur and form submission
        >
            <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder={passwordLabel}
                style={{ fontSize: '16px' }}
                data-testid="password-input"
            />
        </Form.Item>
    );
};

export default PasswordInput;
