import React from 'react';
import { Card, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

const { Text} = Typography;

const NoteInsights = ({note}) => {
  const { t } = useTranslation();

  return (
    <Card
      title={t('session_insights.title')}
      style={{
        background: 'white',
        border: '1px solid #f0f0f0',
        marginBottom: '4px'
    }}
    >
      <div className='session-insights'>
        { note?.getConsiderations() &&
          <>
            <Text type="secondary">
              {t(`case_details.fields.considerations`)}
            </Text>
            <ReactMarkdown style={{ display: 'block', marginBottom: '10px' }}>
              { note.getConsiderations() }
            </ReactMarkdown>
          </>
        }
        { note?.getInconsistencies() &&
          <>
            <Text type="secondary">
              {t(`case_details.fields.inconsistencies`)}
            </Text>
            <ReactMarkdown style={{ display: 'block', marginBottom: '10px' }}>
              { note.getInconsistencies() }
            </ReactMarkdown>
          </>
        }
      </div>
    </Card>
  );
};

export default NoteInsights;
