import { setToken } from './tokenStorage';
import { setCurrentUser } from './userStorage';
import { jwtDecode } from 'jwt-decode';
import i18n from '../i18n';

let redirect = (url) => {
    window.location.href = url;
};

export const setRedirect = (func) => {
    redirect = func;
};

export const handleAuthSuccess = (token, refreshToken, redirectUrl = '/') => {
    // Store the JWT token and refresh token
    setToken(token, refreshToken);

    // Decode the JWT to extract user data
    const decoded = jwtDecode(token);

    // Exclude specific fields
    const { aud, jti, scp, sub, ...filteredDecoded } = decoded;

    // Save user data dynamically using filtered fields
    setCurrentUser({ ...filteredDecoded });

    // Change language based on user's locale and store it in localStorage
    if (decoded.locale && decoded.locale !== i18n.language) {
        i18n.changeLanguage(decoded.locale).then(() => {
            console.log('Language changed to:', decoded.locale);
        }).catch((error) => {
            console.error('Error changing language:', error);
        });
    }

    // Redirect to the specified URL
    redirect(redirectUrl);
};
