import authRequest from '../authRequest';
import CasePresenter from '../../presenters/CasePresenter';

const createCase = async ({ audio = null, text = null } = {}) => {
  try {
    const formData = new FormData();

    if (audio instanceof Blob) {
      // Append audio to the form data if provided
      formData.append('case[audio]', audio);
    }

    if (typeof text === 'string' && text.trim() !== '') {
      // Append text to the form data if provided and not empty
      formData.append('case[text_entry_content]', text);
    }

    if (!audio && (!text || text.trim() === '')) {
      throw new Error('Either "audio" or "text" must be provided.');
    }

    const response = await authRequest('/cases', {
      method: 'POST',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response.status === 200) {
      return new CasePresenter(response.data);
    } else {
      throw new Error('Failed to create case');
    }
  } catch (error) {
    console.error('Error creating case:', error);
    throw error;
  }
};

export default createCase;