import React, { useState } from 'react';
import { Form, Input, Button, Typography, Space, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import createCase from '../../utils/case/createCase';
import { handleError } from '../../utils/errorHandling';
import FeatureBenefits from "../FeatureBenefits";

const { TextArea } = Input;
const { Title } = Typography;

const CaseNew = ({ isVisible, onClose }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const validateSummary = (_, value) => {
    if (!value || value.trim().length < 30) {
      return Promise.reject(new Error(t('case_new.fields.entry_min_length')));
    }
    return Promise.resolve();
  };

  const onFinish = async (values) => {
    try {
      await form.validateFields(); // Validate fields on submit
      setLoading(true);
      const response = await createCase({text: values.summary});
      if (response && response.getUUID()) {
        navigate(`/cases/${response.getUUID()}`);
        onClose();
      } else {
        throw new Error('Failed to create case');
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={
        <Title level={5} style={{ marginTop: '5px', marginBottom: '30px' }}>
          {t('case_new.title')}
        </Title>
      }
      open={isVisible}
      onCancel={onClose}
      footer={null}
      closable={true}
      maskClosable={false}
      width={800}
      data-testid="new-case-modal-text"
    >
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Form.Item
            name="summary"
            rules={[
              { validator: validateSummary, validateTrigger: 'onFinish' }, // Validate on submit
            ]}
          >
            <TextArea
              rows={6}
              placeholder={t('cases.fields.summary_placeholder')}
              style={{ padding: '10px', backgroundColor: 'whitesmoke' }}
            />
          </Form.Item>
          <Space style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              {t('cases.create')}
            </Button>
          </Space>
        </Form>
      </Space>
    </Modal>
  );
};

export default CaseNew;
