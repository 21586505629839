import React, { useState } from 'react';
import { Button, Modal, Select, Typography, message } from 'antd';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import esLocale from 'i18n-iso-countries/langs/es.json';
import 'flag-icons/css/flag-icons.min.css';
import updateUserSettings from '../../utils/user/updateUserSettings';
import { toast } from 'react-hot-toast';

const { Option } = Select;
const { Text } = Typography;

countries.registerLocale(enLocale);
countries.registerLocale(esLocale);

const CallsCountrySelector = ({ onCountrySelected }) => {
  const { t } = useTranslation();

  // Extract the base language and region (e.g., "es" and "MX" from "es-MX")
  const userLanguage = i18n.language.split('-')[0];
  const userRegion = i18n.language.split('-')[1]?.toUpperCase();
  const countryNamesLanguage = userLanguage === 'es' ? 'es' : 'en';
  const countryOptions = Object.entries(countries.getNames(countryNamesLanguage, { select: "official" }));

  // Preselect country based on the region part of the user's locale, fallback to Mexico
  const defaultCountry = userRegion && countries.getAlpha2Code(userRegion, countryNamesLanguage) ? userRegion : "MX";

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(defaultCountry);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
  };

  const handleSave = async () => {
    try {
      await updateUserSettings({ calls_country: selectedCountry });
      toast.success(t('call_empty_state.fields.settings_updated'));
      setIsModalOpen(false);
      if (onCountrySelected) {
        onCountrySelected();
      }
    } catch (error) {
      toast.error(t('call_empty_state.fields.settings_update_failed'));
    }
  };

  return (
    <>
      <Button
        type="primary"
        size="large"
        shape="round"
        onClick={handleOpenModal}
        data-testid="try-calls-button"
      >
        {t('call_empty_state.fields.try_now')}
      </Button>
      <Modal
        title={t('call_empty_state.fields.select_country')}
        open={isModalOpen}
        onOk={handleSave}
        onCancel={handleCloseModal}
        okText={t('common.confirm')}
        cancelText={t('common.cancel')}
      >
        <Text type="secondary" style={{ display: 'block', marginBottom: '1.5rem' }}>
          {t('call_empty_state.fields.select_country_subtitle')}
        </Text>
        <Select
          style={{ width: '100%', marginBottom: '1rem', fontSize: '16px' }}
          onChange={handleCountryChange}
          value={selectedCountry}
          showSearch
          optionFilterProp="children"
        >
          {countryOptions.map(([code, name]) => (
            <Option key={code} value={code}>
              <span className={`fi fi-${code.toLowerCase()}`} style={{ marginRight: 8 }}></span>
              {name}
            </Option>
          ))}
        </Select>
      </Modal>
    </>
  );
};

export default CallsCountrySelector;
