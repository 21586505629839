import React, { useState } from 'react';
import { Button, Form, Typography, Modal, Grid } from 'antd';
import { PhoneOutlined, PhoneFilled } from '@ant-design/icons';
import { PhoneInput } from 'react-international-phone';
import { useTranslation } from 'react-i18next';
import { parsePhoneNumberWithError, isValidPhoneNumber } from 'libphonenumber-js';
import { useTimer } from '../../hooks/useTimer';
import { formatTime } from '../../utils/formatTime';
import createCall from '../../utils/call/createCall';
import connectCall from "../../utils/call/connectCall";
import { toast } from 'react-hot-toast';
import { getCurrentUser } from '../../utils/userStorage';
import 'react-international-phone/style.css';

const { useBreakpoint } = Grid;
const { Paragraph } = Typography;

const CallDialer = ({ onCallEnded }) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const [form] = Form.useForm();
  const [callStatus, setCallStatus] = useState('idle'); // 'idle' | 'connected'
  const [loading, setLoading] = useState(false);
  const [activeCall, setActiveCall] = useState(null);
  const [isCallModalVisible, setIsCallModalVisible] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);
  const [phoneError, setPhoneError] = useState('');
  const timer = useTimer(callStatus === 'connected', hasStarted);

  const currentUser = getCurrentUser();
  const defaultCountryCode = currentUser?.calls_country || 'MX';

  const validatePhone = () => {
    const phoneNumber = form.getFieldValue('to_number') || '';
    if (!phoneNumber) {
      setPhoneError(t('calls.invalid_number'));
      return false;
    }
    try {
      parsePhoneNumberWithError(phoneNumber);
      if (!isValidPhoneNumber(phoneNumber)) {
        setPhoneError(t('calls.invalid_number'));
        return false;
      }
      setPhoneError('');
      return true;
    } catch (error) {
      setPhoneError(t('calls.invalid_number'));
      return false;
    }
  };

  // Called when user clicks the call button or presses "Enter" in the form
  const handleSubmit = async () => {
    const phoneNumber = form.getFieldValue('to_number') || '';

    // Validate only here
    if (!validatePhone(phoneNumber)) {
      return; // Stop if invalid
    }

    // Now we can start the call
    try {
      setLoading(true);
      setHasStarted(true);

      const parsedNumber = parsePhoneNumberWithError(phoneNumber);
      const { twilio_token: twilioToken, call_uuid: callUUID } = await createCall(parsedNumber.format('E.164'));

      const call = await connectCall({
        twilioToken,
        callUUID,
        parsedNumber,
      });

      setActiveCall(call);
      setCallStatus('connected');
      setIsCallModalVisible(true);

      call.on('disconnect', endCall);
      call.on('cancel', endCall);

    } catch (error) {
      setHasStarted(false);
      toast.error(t('calls.call_error'));
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const endCall = () => {
    // Remove event listeners to prevent duplicate event calls
    if (activeCall) {
      activeCall.off('disconnect', endCall);
      activeCall.off('cancel', endCall);
    }

    setCallStatus('idle');
    setHasStarted(false);
    if (activeCall) {
      activeCall.disconnect();
      setActiveCall(null);
    }
    setIsCallModalVisible(false);
    if (onCallEnded) {
      onCallEnded();
    }
  };

  const handlePhoneChange = (phone) => {
    setPhoneError('');
    form.setFieldsValue({ to_number: phone });
    setPhoneError(''); // Clear old errors
  };

  return (
    <>
      <div
        style={{
          maxWidth: '500px',
          margin: '20px auto',
          background: '#80808020',
          padding: '20px',
          borderRadius: '16px',
        }}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          style={{
            maxWidth: '400px',
            margin: '0 auto',
          }}
        >
          <Form.Item
            label={t('calls.dialer_title')}
            name="to_number"
            style={{ marginBottom: 0, paddingBottom: 0 }}
            labelCol={{ style: { paddingBottom: '5px' } }}
            validateStatus={phoneError ? 'error' : ''}
            help={phoneError}
          >
            <div
              style={{
                display: 'flex',
                gap: '16px',
                alignItems: 'center',
              }}
            >
              <div style={{ flex: 1, width: '100%' }} >
                <PhoneInput
                  defaultCountry={defaultCountryCode.toLowerCase()}
                  forceDialCode={true}
                  onChange={handlePhoneChange}
                  placeholder='55 1234 5678'
                  style={{ width: '100%' }}
                  inputStyle={{
                    width: '100%',
                    padding: '12px 16px',
                    fontSize: '16px',
                  }}
                />
              </div>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                icon={<PhoneOutlined className="rotated-icon" />}
                size={ screens.xs ? "small" : "large" }
                style={{
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: 0,
                  background: 'var(--main-color)',
                  border: 'none',
                  boxShadow: '0 4px 12px rgba(76,175,80,0.3)',
                }}
              />
            </div>
          </Form.Item>
        </Form>
      </div>

      {/* In-call modal */}
      <Modal
        open={isCallModalVisible}
        footer={null}
        closable={false}
        centered
        maskClosable={false}
        width={360}
      >
        <div
          style={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '24px',
            background: '#80808012',
            padding: '20px',
            borderRadius: '7px',
          }}
        >
          <Typography.Title level={5} style={{margin: 0}}>
            {t('calls.in_progress')} {form.getFieldValue('to_number')}
          </Typography.Title>
          <div
            style={{
              fontSize: '24px',
              fontWeight: '100',
              marginBottom: '16px',
            }}
          >
            {formatTime(timer)}
          </div>

          <Button
            type="primary"
            danger
            icon={<PhoneFilled style={{transform: 'rotate(225deg)'}}/>}
            onClick={endCall}
            size="large"
            shape="circle"
            style={{
              marginTop: '20px',
              width: '64px',
              height: '64px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '24px',
              background: '#ff4d4f',
              border: 'none',
              boxShadow: '0 4px 12px rgba(255,77,79,0.3)',
            }}
          />
          <Paragraph type="secondary" style={{ margin: 0,}}>
            {t('calls.transcription_note')}
          </Paragraph>
        </div>
      </Modal>
    </>
  );
};

export default CallDialer;
