import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Modal, Form, Select, Button, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import esLocale from 'i18n-iso-countries/langs/es.json';
import 'flag-icons/css/flag-icons.min.css';
import { getCurrentUser, setCurrentUser } from '../utils/userStorage';
import updateUserSurvey from '../utils/user/updateUserSurvey';
import { toast } from "react-hot-toast";

const { Text, Paragraph } = Typography;
const { Option } = Select;

countries.registerLocale(enLocale);
countries.registerLocale(esLocale);

const SurveyModal = () => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const location = useLocation();

  const [selectedRole, setSelectedRole] = useState(null); // Track selected role
  const [selectedCountry, setSelectedCountry] = useState(() => {
    const language = typeof i18n.language === 'string' ? i18n.language : 'es'; // Fallback to 'es'
    const [userLanguage, userRegion] = language.split('-');
    const countryNamesLanguage = userLanguage === 'es' ? 'es' : 'en';
    const defaultCountry = userRegion?.toUpperCase() || 'MX';
    return countries.getAlpha2Code(defaultCountry, countryNamesLanguage) ? defaultCountry : 'MX';
  });

  useEffect(() => {
    // Show survey only if user is on the root path and hasn't completed it and submission hasn't failed
    const currentUser = getCurrentUser();
    const isOnRootPath = location.pathname === '/';
    const submissionFailed = localStorage.getItem('survey_submission_failed') === 'true';

    if (isOnRootPath && currentUser && !currentUser.survey_completed && !submissionFailed) {
      setVisible(true);
    }
  }, [location]);

  const handleSubmit = async (values) => {
    const surveyData = { ...values, country: selectedCountry };

    try {
      // Attempt to update user survey
      await updateUserSurvey(surveyData);

      // Mark survey as completed
      const currentUser = getCurrentUser();
      if (currentUser) {
        const updatedUser = { ...currentUser, survey_completed: true };
        setCurrentUser(updatedUser);
      }

      // Clear the failure flag
      localStorage.removeItem('survey_submission_failed');

      // Close the modal
      setVisible(false);
      toast.success(t('common.thank_you'));
    } catch (error) {
      // Mark submission as failed
      localStorage.setItem('survey_submission_failed', 'true');

      // Show error and close modal
      toast.error(t('survey.errors.failed'));
      setVisible(false);
    }
  };

  // Retrieve roles, workplaces, and specialties from the translation file
  const roles = t('survey.role', { returnObjects: true });
  const workplaces = t('survey.workplace', { returnObjects: true });
  const specialties = t('survey.specialties', { returnObjects: true });

  if (!visible) return null;

  return (
    <Modal
      open={visible}
      title={t('survey.title')}
      onCancel={() => setVisible(false)}
      maskClosable={false} // Prevent closing when clicking outside the modal
      closable={false}
      keyboard={false} // Prevent closing with Esc
      footer={null} // Disable default footer to allow custom placement
    >
      <Paragraph>
        <Text>{t('survey.subtitle')}</Text>
      </Paragraph>
      <Form
        style={{ marginTop: '30px' }}
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        requiredMark={false} // Disable red asterisks
      >
        {/* Role Field */}
        <Form.Item
          label={<strong>{t('survey.role_label')}</strong>}
          name="role"
          rules={[{ required: true, message: t('survey.errors.not_selected') }]}
        >
          <Select
            placeholder={t('survey.role_placeholder')}
            style={{ fontSize: '16px' }}
            onChange={(value) => setSelectedRole(value)} // Track selected role
          >
            {Object.entries(roles).map(([key, value]) => (
              <Option key={key} value={key}>
                {value}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {/* Specialty Field (Conditional) */}
        {selectedRole === 'md' && (
          <Form.Item
            label={<strong>{t('survey.specialty_label')}</strong>}
            name="specialty"
            rules={[{ required: true, message: t('survey.errors.not_selected') }]}
          >
            <Select
              placeholder={t('survey.specialty_placeholder')}
              style={{ fontSize: '16px' }}
              showSearch // Enables typing in the dropdown
              optionFilterProp="children" // Filters based on the option's content
              filterOption={(input, option) =>
                option?.children.toLowerCase().includes(input.toLowerCase())
              } // Custom filter logic for case-insensitive matching
            >
              {Object.entries(specialties).map(([key, value]) => (
                <Option key={key} value={key}>
                  {value}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}

        {/* Workplace Field */}
        <Form.Item
          label={<strong>{t('survey.workplace_label')}</strong>}
          name="workplace"
          rules={[{ required: true, message: t('survey.errors.not_selected') }]}
        >
          <Select placeholder={t('survey.workplace_placeholder')} style={{ fontSize: '16px' }}>
            {Object.entries(workplaces).map(([key, value]) => (
              <Option key={key} value={key}>
                {value}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {/* Country Field */}
        <Form.Item
          label={<strong>{t('survey.country_label')}</strong>}
          name="country"
          initialValue={selectedCountry}
        >
          <Select
            style={{ fontSize: '16px' }}
            showSearch
            optionFilterProp="children"
            value={selectedCountry}
            onChange={(value) => setSelectedCountry(value)}
          >
            {Object.entries(countries.getNames(i18n.language.split('-')[0] === 'es' ? 'es' : 'en', { select: 'official' }))
              .map(([code, name]) => (
                <Option key={code} value={code}>
                  <span className={`fi fi-${code.toLowerCase()}`} style={{ marginRight: 8 }}></span>
                  {name}
                </Option>
              ))}
          </Select>
        </Form.Item>

        {/* Submit Button */}
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Button key="submit" type="primary" size="medium" onClick={() => form.submit()}>
            {t('common.submit')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default SurveyModal;