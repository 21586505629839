import NotePresenter from "./NotePresenter";
import moment from "moment-timezone";
import { parsePhoneNumberFromString } from 'libphonenumber-js';

class CallPresenter {
  constructor(callData) {
    this.call = callData;
  }

  // Helper for formatting E.164 to an international string
  formatPhoneInternational(e164Number) {
    const phoneNumber = parsePhoneNumberFromString(e164Number);
    if (!phoneNumber) {
      // If parsing fails, fall back to the raw string
      return e164Number;
    }
    // Returns a string like "+52 561 106 0917"
    return phoneNumber.formatInternational();
  }

  getToNumber() {
    const raw = this.call?.to_number;
    return raw ? this.formatPhoneInternational(raw) : "";
  }

  isReady() {
    return this.lastNoteIsCompleted();
  }

  isGenerating() {
    return this.getDisplayStatus() === 'processing' && this.isIncomplete();
  }

  isIncomplete() {
    return !this.isClassified() || (this.medicallyRelevant() && !this.lastNoteIsCompleted());
  }

  lastNoteIsCompleted() {
    const note = this.getLastNote();
    return note && note.isComplete();
  }

  getTitle(t) {
    const note = this.getLastNote();
    return note?.isComplete() ? note?.getTitle() : t('recent_calls.generating')
  }

  getExcerpt() {
    if (this.isReady()) {
      return this.getLastNote()?.getSummary();
    } else {
      return "";
    }
  }

  getDisplayProblem() {
    return this.call?.display_problem || "";
  }

  getDisplayStatus() {
    return this.call?.display_status;
  }

  getTime() {
    const completedAt = this.call.completed_at
      ? moment.utc(this.call.completed_at).local().fromNow()
      : null;
    const startedAt = this.call.started_at
      ? moment.utc(this.call.started_at).local().fromNow()
      : null;
    const duration = this.call.duration ? Math.ceil(this.call.duration / 60) : null;

    if (completedAt && duration) {
      return `${duration} min • ${completedAt}`;
    } else if (startedAt) {
      return `${startedAt}`;
    } else {
      return "";
    }
  }

  getUUID() {
    return this.call?.uuid;
  }

  displayNote() {
    return this.isReady();
  }

  displayWidgetsColumn() {
    return this.getLastNote()?.hasInsights();
  }

  // *** Methods needed for NoteCardView compatibility ***
  getLastDoctorNote() {
    return this.getLastNote();
  }
  getLastPatientInstructionsNote() {
    return null;
  }

  getLastDoctorNoteType() {
    const note = this.getLastDoctorNote();
    return note?.getNoteType();
  }

  isDemo() {
    return false;
  }

  canBeRegenerated() {
    return false;
  }

  isDoctorNote() {
    return false;
  }

  displayRegenerate() {
    return false;
  }

  // Additional session interface methods
  getTranscript() {
    return this.call?.gen_transcript;
  }

  getFormattedTranscript() {
    const transcript = this.getTranscript();
    if (!transcript) return '';
    
    // Format the transcript similar to session transcripts
    // This assumes the transcript is in a similar format to sessions
    // Adjust the formatting logic based on your actual transcript format
    return transcript.split('\n')
      .map(line => line.trim())
      .filter(line => line.length > 0)
      .join('\n\n');
  }

  getNotes() {
    return this.call?.notes?.map(note => new NotePresenter(note)) || [];
  }

  getLastNote() {
    const notes = this.getNotes();
    return notes.length === 0 ? null : notes[notes.length - 1];
  }

  isClassified() {
    return this.call?.gen_classification !== null;
  }

  getCompletedAt() {
    return this.call?.completed_at;
  }

  medicallyRelevant() {
    return this.call?.gen_classification && this.call?.gen_classification !== "other";
  }
}

export default CallPresenter;
