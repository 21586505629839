import React from 'react';
import { Modal } from 'antd';

const ExpandableContainer = ({ children, isExpanded, onClose }) => {
  return (
    <Modal
      open={isExpanded}
      onCancel={onClose}
      footer={null}
      width="100%"
      style={{ top: 0, paddingBottom: 0 }}
      styles={{
        body: { padding: 0, height: '100vh', display: 'flex', flexDirection: 'column' }
      }}
      modalRender={(modal) => (
        <div style={{ height: '100vh', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
          {modal}
        </div>
      )}
    >
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div style={{ flex: 1, overflowY: 'auto' }}>
          {children}
        </div>
      </div>
    </Modal>
  );
};

export default ExpandableContainer;
