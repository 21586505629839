import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Space, Typography, Layout, Row, Col } from 'antd';
import BackLink from '../components/BackLink';
import SessionLoadingCard from '../components/session/SessionLoadingCard';
import NoteDetailsTabs from "../components/session/NoteDetailsTabs";
import { handleError } from '../utils/errorHandling';
import fetchCall from '../utils/call/fetchCall';
import CallPresenter from '../presenters/CallPresenter';
import { toast } from 'react-hot-toast';

const { Title } = Typography;
const { Content } = Layout;

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

function CallDetails() {
  const { t } = useTranslation();
  const { uuid } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [call, setCall] = useState(new CallPresenter(null));
  const [loading, setLoading] = useState(true);
  const [loadingFailed, setLoadingFailed] = useState(false);
  const query = new URLSearchParams(location.search);
  const isGenerating = query.get('generating') !== null;

  const pollCall = async (uuid) => {
    const startTime = Date.now();
    const timeout = 300000; // 5 minutes

    while (Date.now() - startTime <= timeout) {
      console.log(`pollSession: fetching call uuid ${uuid}`);
      try {
        const call = await fetchCall(uuid);

        if (call.isReady()) {
          return call; // Exit loop and return the call
        }

        if (!call.isGenerating()) {
          toast.error(t('call_details.errors.generating_failed'));
          navigate('/calls');
          return null;
        }

        await sleep(2000); // Continue polling
      } catch (error) {
        toast.error(t('call_details.errors.polling_error'));
        console.error('Polling error:', error);
        throw error; // Throw to propagate error handling
      }
    }

    throw new Error('Polling timeout reached');
  };

  useEffect(() => {
    let isMounted = true;

    const fetchCallDetails = async () => {
      try {
        setLoading(true);
        const initialCall = await fetchCall(uuid);
        console.error(`initialCall: ${initialCall}`);
        if (!isMounted) return;

        setCall(initialCall);
        setLoading(false);

        if (isGenerating) {
          const updatedCall = await pollCall(uuid);
          if (!isMounted || !updatedCall) return;
          setCall(updatedCall);

          const updatedSearchParams = new URLSearchParams(location.search);
          updatedSearchParams.delete('generating');
          navigate(`/calls/${uuid}?${updatedSearchParams.toString()}`, { replace: true });
        }
      } catch (error) {
        handleError(error);
        setLoadingFailed(true);
        setLoading(false);
      }
    };

    fetchCallDetails();
    return () => { isMounted = false; };
  }, [uuid, isGenerating, location.search, navigate, t]);

  if (loading) return <SessionLoadingCard note_type_slug='call' />;
  if (!loadingFailed && !call?.isReady()) return <SessionLoadingCard note_type_slug='call' />;
  if (loadingFailed) {
    handleError(t('call_details.errors.loading_failed'));
    navigate('/calls');
    return null;
  }

  return (
    <Content className='page'>
      <Space direction='vertical' size={16} style={{ width: '100%' }}>
        <Row align="middle" gutter={[16, 16]} style={{ marginBottom: '16px' }}>
          <Col flex="none">
            <BackLink to='/calls' label={t('navigation_header.calls')} />
          </Col>
          <Col flex="auto">
            <Title level={4} style={{ margin: 0, marginLeft: '10px' }}>
              {call.getTitle(t)}
            </Title>
          </Col>
        </Row>
        <div style={{ maxWidth: call.displayWidgetsColumn() ? '1000px' : '800px' }}>
          {call.displayNote() && <NoteDetailsTabs source={call} />}
          {call.displayNote() &&
            <div style={{ marginTop: '16px' }}>
              <BackLink to='/calls' label={t('navigation_header.calls')} />
            </div>
          }
        </div>
      </Space>
    </Content>
  );
}

export default CallDetails;
