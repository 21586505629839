import React, { useState } from 'react';
import { Typography, Card, Space, Grid } from 'antd';
import { useTranslation } from 'react-i18next';
import FeatureBenefits from "../FeatureBenefits";
import CallsCountrySelector from "./CallsCountrySelector";
import { getCurrentUser } from '../../utils/userStorage';
import CallDialer from "./CallDialer";
import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import esLocale from 'i18n-iso-countries/langs/es.json';
import supportedFeature from "../../utils/supportedFeature";
// Register locales
countries.registerLocale(enLocale);
countries.registerLocale(esLocale);

const { useBreakpoint } = Grid;
const { Title, Text, Paragraph } = Typography;

const bodyStyle = {
  textAlign: 'center',
  padding: '0px',
  paddingBottom: '20px'
}

const spaceStyle = {
  width: '100%',
};

const notSupportedStyle = {
  maxWidth: '500px',
  margin: '40px auto',
  background: '#80808012',
  padding: '20px',
  borderRadius: '16px',
}

const CallEmptyState = ({onCallEnded}) => {
  const { t } = useTranslation();
  const { screens } = useBreakpoint();
  const { i18n } = useTranslation();
  const locale = i18n.language;
  const userLanguage = locale.split('-')[0];
  const currentUser = getCurrentUser();

  const [reloadTrigger, setReloadTrigger] = useState(0); // Tracks changes to re-render

  const getCallsCountryName = () => {
    const countryCode = currentUser.calls_country;
    if (!countryCode) return null; // Handle cases where no country is set

    return countries.getName(countryCode, userLanguage) || null; // Return country name or null if invalid
  }

  const handleCountrySelected = () => {
    setReloadTrigger((prev) => prev + 1); // Increment to trigger re-render
  };

  return (
    <Card
      styles={{ body: bodyStyle }}
      data-testid="call-empty-state-container"
    >
      <Space direction="vertical" size="middle" style={spaceStyle} key={reloadTrigger}>
        <Title level={3}>{t('call_empty_state.title')}</Title>
        {supportedFeature("Calls") && <CallDialer onCallEnded={onCallEnded} />}
        <FeatureBenefits featureKey="call_empty_state" />
        {currentUser?.calls_country && !supportedFeature("Calls") && (
          <div style={notSupportedStyle}>
            <Paragraph>
              <Text style={{ fontSize: '16px'}}>{t('call_empty_state.fields.not_supported', { country: getCallsCountryName() })}</Text>
            </Paragraph>
          </div>
        ) }
        {!currentUser?.calls_country && <CallsCountrySelector onCountrySelected={handleCountrySelected} />}
      </Space>
    </Card>
  );
};

export default CallEmptyState;