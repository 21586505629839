import { useState, useEffect } from 'react';

export const useTimer = (isRunning, hasStarted) => {
    const [timer, setTimer] = useState(0);

    useEffect(() => {
        if (!hasStarted) {
            setTimer(0);
        }
    }, [hasStarted]);

    useEffect(() => {
        let interval;
        if (isRunning) {
            interval = setInterval(() => {
                setTimer((prev) => prev + 1);
            }, 1000);
        }
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [isRunning]);

    return timer;
};
