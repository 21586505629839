import authRequest from '../authRequest';
import CallPresenter from '../../presenters/CallPresenter';

const fetchCallsList = async () => {
  try {
    const response = await authRequest('/calls', { method: 'GET' });
    if (response.status === 200) {
      // Map raw call data to CallPresenter instances and filter invalid calls in one step
      return response.data.map((callData) => new CallPresenter(callData))
    } else {
      throw new Error('Fetching calls failed');
    }
  } catch (error) {
    console.error('Error fetching calls:', error);
    throw error;
  }
};

export default fetchCallsList;