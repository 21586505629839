import React, { useState } from 'react';
import { Input, Typography, Card, Tooltip, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons';
import createCaseTextEntry from '../../utils/case/createCaseTextEntry';
import { handleError } from "../../utils/errorHandling";

const { TextArea } = Input;
const { Text } = Typography;

const cardStyle = {
  position: 'relative',
};

const bodyStyle = {
  padding: '18px',
  paddingBottom: '8px',
  paddingTop: '14px',
};

const iconStyle = {
  position: 'absolute',
  top: '16px',
  right: '16px',
  color: 'var(--text-color-secondary)',
  fontSize: '14px',
};

const textAreaStyle = {
  marginTop: '12px',
  marginBottom: '5x',
  paddingBottom: '5px',
  padding: '12px',
  background: 'whitesmoke',
  fontSize: '16px',
};

const CaseTextEntryForm = ({ caseUuid, onEntrySubmitted }) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();

  const validateTextEntry = (_, value) => {
    if (!value || value.trim().length < 10) {
      return Promise.reject(new Error(t('case_text_entry.too_short_error')));
    }
    return Promise.resolve();
  };

  const handleSubmitTextEntry = async (values) => {
    const trimmedEntry = values.textEntry.trim();

    if (!trimmedEntry || isSubmitting) return;

    try {
      setIsSubmitting(true);
      const response = await createCaseTextEntry(caseUuid, trimmedEntry);

      if (response && response.uuid) {
        form.resetFields(); // Clear the input after successful submission
        onEntrySubmitted(response.uuid);
      } else {
        throw new Error('No valid response received');
      }
    } catch (error) {
      handleError(error)
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      form.submit(); // Trigger form submission on Enter key press
    }
  };

  return (
    <Card
      style={cardStyle}
      styles={{ body: bodyStyle }}>
      <Text>{t('case_text_entry.title')}</Text>
      <Tooltip title={t('case_text_entry.tooltip')}>
        <InfoCircleOutlined style={iconStyle} />
      </Tooltip>
      <Form form={form} onFinish={handleSubmitTextEntry} layout="vertical">
        <Form.Item
          name="textEntry"
          rules={[
            { validator: validateTextEntry, validateTrigger: 'onFinish' }, // Validate on submit
          ]}
        >
          <TextArea
            rows={3}
            placeholder={t('case_text_entry.placeholder')}
            disabled={isSubmitting}
            style={textAreaStyle}
            data-testid='text-entry-area'
            onKeyPress={handleKeyPress} // Attach the key press handler to the TextArea
          />
        </Form.Item>
        <div style={{ textAlign: 'right' }}>
          <Text type="secondary" style={{ fontSize: '12px' }}>
            {t('case_text_entry.submit_hint')}
          </Text>
        </div>
      </Form>
    </Card>
  );
};

export default CaseTextEntryForm;
