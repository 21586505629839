import authRequest from '../../utils/authRequest';

const createCall = async (toNumber) => {
  try {
    const response = await authRequest('/calls', {
      method: 'POST',
      data: { to_number: toNumber },
    });

    if (response.status === 200) {
      return response.data; // Return relevant data, e.g., Twilio token and call UUID
    } else {
      throw new Error('Failed to initiate call');
    }
  } catch (error) {
    console.error('Error creating call:', error);
    throw error;
  }
};

export default createCall;