import React from "react";
import { Button } from "antd";
import { AudioOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const ToggleRecordingButton = ({
                                 isRecording,
                                 onStart,
                                 onStop,
                               }) => {
  const { t } = useTranslation();

  // Decide button label, icon, and disabled state:
  let buttonLabel = t("case_new.fields.start_recording");
  let buttonIcon = <AudioOutlined />;
  let buttonDisabled = false;

  // If recording, label is "Finish"
  if (isRecording) {
    buttonLabel = t("case_new.fields.finish_recording");
    buttonIcon = <ThunderboltOutlined />;
  }

  const handleClick = () => {
    if (isRecording) {
      onStop();
    } else {
      onStart();
    }
  };

  return (
    <Button
      type="primary"
      shape="round"
      size="large"
      onClick={handleClick}
      icon={buttonIcon}
      disabled={buttonDisabled}
      data-testid="toggle-recording-button"
      style={{
        backgroundColor: isRecording ? "#00B3B3" : "#008B8B",
        borderColor: isRecording ? "#00B3B3" : "#008B8B",
        fontSize: "16px",
        marginBottom: "20px",
      }}
    >
      {buttonLabel}
    </Button>
  );
};

export default ToggleRecordingButton;