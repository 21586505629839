import React from 'react';
import { Layout, Typography, Row, Col, Grid } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  MessageOutlined,
  FolderOpenOutlined,
  FileTextOutlined,
  PhoneOutlined,
} from '@ant-design/icons';
import FAQ from '../components/FAQ';
import HomeFeature from '../components/home/HomeFeature';
import { getCurrentUser } from '../utils/userStorage';
import supportedFeature from "../utils/supportedFeature";

const { Content } = Layout;
const { Title } = Typography;
const { useBreakpoint } = Grid;

const Home = () => {
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const currentUser = getCurrentUser();

  const features = [
    {
      key: 'answers',
      icon: <MessageOutlined />,
      iconBackgroundColor: '#fffbe6',
      iconColor: '#faad14',
      link: '/queries/new',
    },
    {
      key: 'sessions',
      icon: <FileTextOutlined />,
      iconBackgroundColor: '#e6f7ff',
      iconColor: '#1890ff',
      link: '/sessions',
    },
    {
      key: 'cases',
      icon: <FolderOpenOutlined />,
      iconBackgroundColor: '#f6ffed',
      iconColor: '#52c41a',
      link: '/cases',
    },
  ];

  if (supportedFeature("Calls")) {
    features.push({
      key: 'calls',
      icon: <PhoneOutlined className="rotated-icon" />,
      iconBackgroundColor: '#fff0f6',
      iconColor: '#eb2f96',
      link: '/calls',
    });
  }

  // Responsive spans based on the number of features
  const getColSpan = () => {
    // 3 per row for md, 4 per row for smaller screens
    return { xs: 24, sm: 12, md: 8, lg: 8 };
  };

  return (
    <Content className="page home-page">
      {screens.md && (
        <Row justify="center" style={{ marginBottom: '20px' }}>
          <Col>
            <Title level={2} style={{ fontWeight: '300', textAlign: 'center' }}>
              {t('home.title')}
            </Title>
          </Col>
        </Row>
      )}
      <Row gutter={[16, 16]}>
        {features.map((feature) => (
          <Col {...getColSpan()} key={feature.key}>
            <HomeFeature feature={feature} />
          </Col>
        ))}
      </Row>
      <FAQ />
    </Content>
  );
};

export default Home;