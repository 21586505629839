const CURRENT_USER = 'currentUser';

export const setCurrentUser = (userData) => {
    if (userData) {
        localStorage.setItem(CURRENT_USER, JSON.stringify(userData));
    }
};

export const getCurrentUser = () => {
    const data = localStorage.getItem(CURRENT_USER);
    try {
        // Parse the JSON string back to an object
        return data ? JSON.parse(data) : null;
    } catch (error) {
        console.error('Error parsing user data from localStorage:', error);
        return null; // Return null if parsing fails
    }
};

export const clearCurrentUser = () => {
    localStorage.removeItem(CURRENT_USER);
    // if survey submission failed, we want the user to try again next time they log-in
    localStorage.removeItem('survey_submission_failed');
};
