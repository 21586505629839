import React from 'react';
import {Layout, Skeleton, Space} from 'antd';

const { Content } = Layout;

function LoadingCard() {
  return (
    <Content className='page'>
      <div data-testid='loading-skeleton'>
        <Skeleton active />
        <Space />
        <Skeleton active />
        <Space />
        <Skeleton active />
      </div>
    </Content>
  );
}

export default LoadingCard;