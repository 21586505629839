import React, { useRef } from 'react';
import { Card, Menu, Dropdown, Button } from 'antd';
import MarkDown from '../Markdown';
import CopyToClipboardButton from './CopyToClipboardButton';
import DownloadNoteAsPDFButton from './DownloadNoteAsPDFButton';
import ReviewTranscriptButton from './ReviewTranscriptButton';
import EditButton from './EditButton';
import RegenerateNote from './RegenerateNote';
import { EllipsisOutlined } from '@ant-design/icons';
import ThumbsRating from "../ThumbsRating";
import { useTranslation } from 'react-i18next';

const NoteCardView = ({ currentNote, source, handleEditButtonClick }) => {
  const { t } = useTranslation();
  const noteContentRef = useRef();
  
  const noteBodyFormatted = () => (
    <div ref={noteContentRef}>
      <MarkDown content={currentNote.getBody()} />
    </div>
  );

  const transcriptFormatted = () => {
    if (!source?.isDemo() && currentNote.isValid() && currentNote.isDoctorNote()) {
      return (
        <div style={{ float: 'right' }}>
          <ReviewTranscriptButton source={source} />
        </div>
      );
    }
    return null;
  };

  const dropdownMenu = (
    <Menu>
      {currentNote.isDoctorNote() && source.displayRegenerate() && (
        <Menu.Item key="2">
          <RegenerateNote sessionUuid={source.getUUID()} disabled={!currentNote.canBeRegenerated()} />
        </Menu.Item>
      )}
    </Menu>
  );

  const headerContent = (
    <div className='session-details--buttons-container'>
      <CopyToClipboardButton textToCopy={currentNote.getBody()} disabled={!currentNote.isValid()} />
      <DownloadNoteAsPDFButton note={currentNote} noteContentRef={noteContentRef} />
      <EditButton handleEditButtonClick={handleEditButtonClick} />
      {!source.isDemo() && source.displayRegenerate() && currentNote.isDoctorNote() && (
        <Dropdown overlay={dropdownMenu} trigger={['click']} placement="bottomRight">
          <Button icon={<EllipsisOutlined />} />
        </Dropdown>
      )}
    </div>
  );

  return (
    <div>
      <Card
        style={{ borderRadius: '0 10px 10px 10px', borderColor: '#E0E0E0', borderTop: 'none' }}
        bordered={true}
        extra={headerContent}
      >
        {transcriptFormatted()}
        {noteBodyFormatted()}
        {!source.isDemo() && currentNote.isValid() && (
          <div style={{display: 'flex', justifyContent: 'left', marginTop: '30px'}}>
            <ThumbsRating
              rateableUuid={currentNote.getUUID()}
              rateableType="Note"
              hint={t('session_details.fields.rating_hint')}
            />
          </div>
        )}
      </Card>
    </div>
  );
};

export default NoteCardView;
