import React, { useState, useEffect } from 'react';
import {Table, Grid, Typography, Skeleton, Space, Layout} from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import fetchCallsList from '../utils/call/fetchCallsList';
import CallEmptyState from '../components/call/CallEmptyState';
import CallDialer from '../components/call/CallDialer';
import LoadingCard from '../components/LoadingCard';

const { Text } = Typography;
const { Content } = Layout;
const { useBreakpoint } = Grid;
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const Calls = () => {
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const [recentCalls, setRecentCalls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [reloadTrigger, setReloadTrigger] = useState(0);
  const [selectedPhone, setSelectedPhone] = useState('');

  const truncateText = (text) => {
    return text?.length > 200 ? `${text.substring(0, 200)}...` : text;
  };

  const handleCallEnded = async () => {
    await sleep(1000);
    setReloadTrigger((prev) => prev + 1);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const calls = await fetchCallsList();
        setRecentCalls(calls);
      } catch (error) {
        toast.error(t('calls.errors.fetch_error'));
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [reloadTrigger]);

  const columns = [
    {
      title: t('recent_calls.header'),
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: '15%',
      render: (_, record) => {
        // record is a CallPresenter
        return record.getToNumber();
      }
    },
    {
      title: '',
      dataIndex: 'title',
      key: 'title',
      width: '20%',
      render: (_, record) =>
        record.isReady() ? (
          <Link to={`/calls/${record.getUUID()}`}>{record.getTitle(t)}</Link>
        ) : record.isGenerating() ? (
          <Link to={`/calls/${record.getUUID()}?generating=1`}>{t('recent_calls.generating')}</Link>
        ) : (
          <>
            <div><Text>{record.getDisplayStatus()}</Text></div>
            <Text type="secondary">{record.getDisplayProblem()}</Text>
          </>
        ),
    },
  ];

  if (screens.md) {
    columns.push(
      {
        title: '',
        dataIndex: 'summary',
        key: 'summary',
        width: '40%',
        render: (_, record) => (<Text>{truncateText(record.getExcerpt())}</Text>)
      },
      {
        title: '',
        dataIndex: 'time',
        key: 'time',
        width: '20%',
        render: (_, record) => (
          <div className="markdown-content">{truncateText(record.getTime())}</div>
        ),
      }
    );
  }

  return (
    <Content className="page calls-page">
      {recentCalls.length > 0 && <CallDialer initialPhoneNumber={selectedPhone} onCallEnded={handleCallEnded}/>}
      {loading ? (<LoadingCard/>) :
        recentCalls.length > 0 ? (
          <div>
            <Table
              dataSource={recentCalls}
              columns={columns}
              pagination={{
                pageSize: 10,
                showSizeChanger: false,
              }}
              rowKey={(record) => record.getUUID()}
            />
          </div>
      ) : (
        <CallEmptyState onCallEnded={handleCallEnded}/>
      )}
    </Content>
  );
};

export default Calls;
