import React, { useEffect } from 'react';
import { ErrorBoundary } from './bugsnag';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ConfigProvider, Layout } from 'antd';
import { Toaster } from 'react-hot-toast';
import { initGA } from './analytics';
import PageViewHandler from './components/PageViewHandler';
import { useRetryUpload } from './hooks/useRetryUpload';
import { useNetworkStatus } from './hooks/useNetworkStatus';
import { protectedRoutes, publicRoutes } from './routes';
import ProtectedLayout from './components/layouts/ProtectedLayout';
import PublicLayout from './components/layouts/PublicLayout';
import BlankLayout from './components/layouts/BlankLayout';
import SurveyModal from './components/SurveyModal';
import './i18n';
import './App.css';

const { Content } = Layout;

function App() {
  useEffect(() => {
    initGA();
  }, []);

  const isOnline = useNetworkStatus();
  useRetryUpload(isOnline, 20000);

  return (
    <ErrorBoundary>
      <div className='App' data-testid='app'>
        <ConfigProvider theme={{
          token: {
            colorPrimary: 'var(--main-color)',
            colorPrimaryActive: 'var(--main-color)',
            colorPrimaryHover: 'var(--hover-color)',
          },
          components: {
            Tabs: {
              itemSelectedColor: '#333333',
              itemHoverColor: 'var(--main-color)',
            },
            Button: {
              colorPrimary: 'var(--main-color)',
            },
            Select: {
              controlItemBgActive: '#e6f7ff', // Background for selected option
              controlItemBgHover: '#bae7ff', // Background on hover
              controlItemColorActive: '#fff', // Text color for selected option
            },
          }
        }}>
          <BrowserRouter>
            <PageViewHandler />
            <Layout>
              <Layout>
                <Content>
                  <Routes>
                    {protectedRoutes.map((route, index) => (
                      <Route
                        key={index}
                        path={route.path}
                        element={
                          <ProtectedLayout hideSidenav={route.hideSidenav}>
                            {route.element}
                          </ProtectedLayout>
                        }
                      />
                    ))}
                    {publicRoutes.map((route, index) => (
                      <Route
                        key={index}
                        path={route.path}
                        element={
                          route.layout === "blank" ? (
                            <BlankLayout>{route.element}</BlankLayout>
                          ) : (
                            <PublicLayout>{route.element}</PublicLayout>
                          )
                        }
                      />
                    ))}
                  </Routes>
                </Content>
              </Layout>
            </Layout>
            <Toaster
              position="top-center"
              containerStyle={{
                marginTop: '55px',
              }}
            />
            <SurveyModal/>
          </BrowserRouter>
        </ConfigProvider>
      </div>
    </ErrorBoundary>
  );
}

export default App;
