import moment from 'moment';
import CaseTextEntryPresenter from './CaseTextEntryPresenter';

class CasePresenter {
  constructor(caseData) {
    // Case Summary
    this.uuid = caseData.uuid;
    this.notValid = caseData.not_valid;
    this.patientName = caseData.patient_name;
    this.genTitle = caseData.gen_title;
    this.genDob = caseData.gen_dob;
    this.genAge = caseData.gen_age;
    this.genSex = caseData.gen_gender;
    this.genCivilStatus = caseData.gen_civil_status;
    this.genOccupation = caseData.gen_occupation;
    this.genLocation = caseData.gen_location;
    this.genReligion = caseData.gen_religion;
    this.genWeight = caseData.gen_weight;
    this.genHeight = caseData.gen_height;
    this.genBmi = caseData.gen_bmi;
    this.genBloodType = caseData.gen_blood_type;
    this.genSummary = caseData.gen_summary;

    // Clinical Presentation
    this.genCurrentIllnessHistory = caseData.gen_current_illness_history;
    this.genPastMedicalHistory = caseData.gen_past_medical_history;
    this.genPastSurgicalHistory = caseData.gen_past_surgical_history;

    // Medications and Allergies
    this.genMedications = caseData.gen_medications;
    this.genAllergies = caseData.gen_allergies;

    // Lifestyle and Family Context
    this.genSocialHistory = caseData.gen_social_history;
    this.genFamilyHistory = caseData.gen_family_history;

    // Clinical Evaluation
    this.genReviewOfSystems = caseData.gen_review_of_systems;
    this.genVitalSignsAndPhysicalExam = caseData.gen_vital_signs_and_physical_exam;

    // Diagnostic Evaluation
    this.genLaboratoryStudies = caseData.gen_laboratory_studies;
    this.genImagingStudies = caseData.gen_imaging_studies;
    this.genAssessment = caseData.gen_assessment;
    this.genSpecialistConsultations = caseData.gen_specialist_consultations;

    // Treatment and Follow-Up
    this.genTreatmentPlan = caseData.gen_treatment_plan;
    this.genFollowUp = caseData.gen_follow_up;

    // Insights
    this.genRiskFactors = caseData.gen_risk_factors;
    this.genInconsistencies = caseData.gen_inconsistencies;
    this.genConsiderations = caseData.gen_considerations;

    // Additional Information
    this.createdAt = caseData.created_at;
    this.updatedAt = caseData.updated_at;
    this.queries = caseData.queries;
    this.textEntries = caseData.text_entries;
  }

  checkValue(value) {
    return value && value !== "0" ? value : 'N/A';
  }

  getUUID() {
    return this.checkValue(this.uuid);
  }

  isReady() {
    const textEntry = this.getLatestTextEntry();
    if (!textEntry) return false;
    return textEntry.isCompleted();
  }

  isInvalid() {
    return this.notValid;
  }

  // if case is not invalid (e.g. either still processing or processed as valid), we treat it as valid
  isValid() {
    return !this.isInvalid();
  }

  isReadyButInvalid() {
    return this.isReady() && this.isInvalid();
  }

  getTitle() {
    return this.checkValue(this.genTitle);
  }

  getCreatedAt(format = 'MMMM D, YYYY') {
    return this.createdAt ? moment.utc(this.createdAt).local().format(format) : 'N/A';
  }

  getUpdatedAt() {
    return this.updatedAt ? moment.utc(this.updatedAt).local().fromNow() : 'N/A';
  }

  getSummary() {
    return this.checkValue(this.genSummary);
  }

  getCivilStatus() {
    const civilStatus = this.checkValue(this.genCivilStatus);
    return civilStatus.charAt(0).toUpperCase() + civilStatus.slice(1);
  }

  getTruncatedSummary(length = 100) {
    if (!this.genSummary) return 'N/A';
    if (this.genSummary.length <= length) return this.genSummary;
    return this.genSummary.substring(0, length) + '...';
  }

  getPatientName() {
    return this.checkValue(this.patientName);
  }

  getDob() {
    if (this.genDob) {
      return this.checkValue(moment(this.genDob).format('DD/MM/YYYY'));
    } else if (this.genAge) {
      const currentDate = new Date();
      const birthYear = currentDate.getFullYear() - parseInt(this.genAge);
      return `${birthYear}`;
    }
    return 'N/A';
  }

  getAge() {
    if (this.genAge) {
      return this.checkValue(this.genAge);
    } else if (this.genDob) {
      const birthDate = moment(this.genDob);
      const currentDate = moment();
      return currentDate.diff(birthDate, 'years');
    }
    return 'N/A';
  }

  getGender() {
    const gender = this.checkValue(this.genSex);
    return gender.charAt(0).toUpperCase() + gender.slice(1);
  }

  getWeight() {
    return this.checkValue(this.genWeight);
  }

  getHeight() {
    return this.checkValue(this.genHeight);
  }

  getBmi() {
    return this.checkValue(this.genBmi);
  }

  getBloodType() {
    return this.checkValue(this.genBloodType);
  }

  getOccupation() {
    return this.checkValue(this.genOccupation);
  }

  getLocation() {
    return this.checkValue(this.genLocation);
  }

  getReligion() {
    return this.checkValue(this.genReligion);
  }

  getCurrentIllnessHistory() {
    return this.checkValue(this.genCurrentIllnessHistory);
  }

  getPastMedicalHistory() {
    return this.checkValue(this.genPastMedicalHistory);
  }

  getPastSurgicalHistory() {
    return this.checkValue(this.genPastSurgicalHistory);
  }

  getMedications() {
    return this.checkValue(this.genMedications);
  }

  getAllergies() {
    return this.checkValue(this.genAllergies);
  }

  getSocialHistory() {
    return this.checkValue(this.genSocialHistory);
  }

  getFamilyHistory() {
    return this.checkValue(this.genFamilyHistory);
  }

  getReviewOfSystems() {
    return this.checkValue(this.genReviewOfSystems);
  }

  getVitalSignsAndPhysicalExam() {
    return this.checkValue(this.genVitalSignsAndPhysicalExam);
  }

  getRiskFactors() {
    return this.checkValue(this.genRiskFactors);
  }

  getLaboratoryStudies() {
    return this.checkValue(this.genLaboratoryStudies);
  }

  getImagingStudies() {
    return this.checkValue(this.genImagingStudies);
  }

  getAssessment() {
    return this.checkValue(this.genAssessment);
  }

  getSpecialistConsultations() {
    return this.checkValue(this.genSpecialistConsultations);
  }

  getTreatmentPlan() {
    return this.checkValue(this.genTreatmentPlan);
  }

  getFollowUp() {
    return this.checkValue(this.genFollowUp);
  }

  getInconsistencies() {
    return this.genInconsistencies;
  }

  getConsiderations() {
    return this.checkValue(this.genConsiderations);
  }

  displayInsights() {
    return this.genConsiderations || this.genInconsistencies;
  }

  getQueries() {
    return this.queries;
  }

  getLatestTextEntry() {
    const lastEntry = this.textEntries?.at(-1);
    return lastEntry ? new CaseTextEntryPresenter(lastEntry) : null;
  }
}

export default CasePresenter;
