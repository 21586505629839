import React from 'react';
import { Typography, Space, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import FeatureBenefits from "../FeatureBenefits";
import SessionRecorder from "./SessionRecorder";

const { Title, Text } = Typography;

const bodyStyle = {
  textAlign: 'center',
  padding: '0px',
  paddingBottom: '20px'
}

const spaceStyle = {
  width: '100%',
};

const SessionEmptyState = ({ showModal }) => {
  const { t } = useTranslation();

  return (
    <Card
      styles={{ body: bodyStyle }}
      data-testid="session-empty-state-container"
    >
      <Space direction="vertical" size="small" style={spaceStyle}>
        <Title level={3} style={{marginTop: '20px'}}>{t('session_empty_state.title')}</Title>
        <FeatureBenefits featureKey='session_empty_state'/>
        <div className='session-recorder'>
          <SessionRecorder showDemo={true}/>
        </div>
      </Space>
    </Card>
);
};

export default SessionEmptyState;
