import React, { useState, useEffect } from 'react';
import { Button, List, Skeleton, Card, Drawer, Grid, Pagination } from 'antd';
import fetchQueriesList from '../../utils/query/fetchQueriesList';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NewQuestionButton from './NewQuestionButton';
import { MenuOutlined } from "@ant-design/icons";

const { useBreakpoint } = Grid;

const RecentQueries = ({ activeQueryUuid, reloadTrigger, onNewQueryPage = false }) => {
  const [queries, setQueries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEntries, setTotalEntries] = useState(0);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;

    const fetchRecentQueries = async (page) => {
      if (!isMounted) return;
      setLoading(true);
      const { queries, pagination } = await fetchQueriesList(page);
      if (isMounted) {
        setQueries(queries);
        setTotalEntries(pagination.total_entries);
        setLoading(false);
      }
    };

    fetchRecentQueries(currentPage);

    return () => {
      isMounted = false; // Prevent updates to unmounted components
    };
  }, [reloadTrigger, currentPage]);

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page); // Update the current page when the pagination is changed
  };

  const handleLinkClick = (path) => {
    if (path !== location.pathname) {
      toggleDrawer(); // Close drawer only if needed
      setTimeout(() => {
        navigate(path);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 200);
    }
  };

  const truncateText = (text, charLimit) => {
    return text.length > charLimit ? text.slice(0, charLimit) + '...' : text;
  };

  if (loading) {
    return <Skeleton active />;
  }

  const listItemStyle = {
    backgroundColor: '#f5f5f5',
    fontWeight: 'bold',
    padding: '5px 6px',
    borderRadius: '4px',
  };

  const inactiveItemStyle = {
    backgroundColor: 'inherit',
    fontWeight: 'normal',
    padding: '5px 6px',
  };

  const content = (
    <>
      <List
        bordered={false}
        dataSource={queries}
        renderItem={query => (
          <List.Item
            key={query.uuid}
            style={((query.uuid === activeQueryUuid) && (queries.length > 1)) ? listItemStyle : inactiveItemStyle}
            data-testid={`query-${query.uuid}`}
          >
            <Link
              to={`/queries/${query.uuid}`}
              onClick={() => handleLinkClick(`/queries/${query.uuid}`)}
              style={{ display: 'block', width: '100%' }}
            >
              {truncateText(query.question, 100)}
            </Link>
          </List.Item>
        )}
      />
      <Pagination
        current={currentPage}
        total={totalEntries}
        pageSize={20}
        onChange={handlePageChange}
        hideOnSinglePage={true}
        showQuickJumper={false}
        showSizeChanger={false}
        style={{ marginTop: '16px', textAlign: 'center' }}
      />
    </>
  );

  return (
    <div key="recent-queries">
      {screens.md ? (
        <Card
          key="recent-queries-card-md"
          title={t('recent_queries.title')}
          extra={!onNewQueryPage && <NewQuestionButton />}
          className="recent-queries-card"
          style={{ height: 'auto' }} // Adjust height to fit content
        >
          {content}
        </Card>
      ) : (
        <div key="recent-queries-not-md">
          <div style={{ display: 'flex', justifyContent: 'left', gap: '8px', marginTop: 20 }}>
            <Button icon={<MenuOutlined />} type="default" onClick={toggleDrawer} data-testid="drawer-toggle-button">
              {t('recent_queries.title')}
            </Button>
            {!onNewQueryPage && (
              <NewQuestionButton onClick={() => {
                toggleDrawer();
                navigate('/new-query');
              }} />
            )}
          </div>
          <Drawer
            title={t('recent_queries.title')}
            placement="left"
            closable={true}
            onClose={toggleDrawer}
            open={drawerVisible}
            bodyStyle={{ padding: 6 }}
            className="recent-queries-drawer"
          >
            {content}
          </Drawer>
        </div>
      )}
    </div>
  );
};

export default RecentQueries;
