import React, { useState, useEffect, useRef } from 'react';
import { Input, Typography, List, Grid } from 'antd';
import { useTranslation } from 'react-i18next';
import updateSession from '../../utils/session/updateSession';

const { Text } = Typography;
const { useBreakpoint } = Grid;

const MAX_NOTES = 10; // Maximum allowed notes

const SessionRecorderNotepad = ({ sessionUuid }) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const [notes, setNotes] = useState([]); // Array to store each submitted note
  const [currentNote, setCurrentNote] = useState(''); // Input for new note
  const [editingIndex, setEditingIndex] = useState(null); // Track which note is being edited
  const [error, setError] = useState(''); // Track error message
  const [isContainerFocused, setIsContainerFocused] = useState(false); // Track focus for container styling

  const notesContainerRef = useRef(null); // Ref for scrolling the notes list
  const mainInputRef = useRef(null); // Ref for the main input field

  const hintTextStyle = {
    display: 'block',
    fontSize: '12px',
    marginTop: '8px',
    marginLeft: '3px',
    textAlign: 'right',
  };

  const updateUserNotes = async (updatedNotes) => {
    const markdownNotes =
      updatedNotes.length > 0
        ? updatedNotes.map((note) => `- ${note}`).join('\n')
        : ''; // Set blank text if all notes are deleted

    try {
      await updateSession(sessionUuid, markdownNotes);
    } catch (error) {
      console.error('Failed to update session notes:', error);
    }
  };

  const resetZoomOnMobile = () => {
    if (!screens.md) {
      // Only execute for small screens (mobile)
      document.activeElement?.blur(); // Deselect input to remove keyboard focus
      window.scrollTo(0, 0); // Reset scroll position
    }
  };

  const handleAddNote = async (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      const trimmedNote = currentNote.trim();

      if (notes.length >= MAX_NOTES) {
        setError(t('session_recorder_notepad.errors.max_notes_reached'));
        return; // Prevent adding more than 10 notes
      }

      if (trimmedNote) {
        const updatedNotes = [...notes, trimmedNote];
        setNotes(updatedNotes);
        setCurrentNote('');
        setError('');
        await updateUserNotes(updatedNotes);
        resetZoomOnMobile();
      }
    }
  };

  const handleFocus = () => setIsContainerFocused(true);
  const handleBlur = () => setIsContainerFocused(false);

  const handleMainInputKeyDown = async (e) => {
    if (
      (e.key === 'Backspace' || e.key === 'Delete') &&
      currentNote.trim() === '' &&
      notes.length > 0
    ) {
      e.preventDefault();
      setEditingIndex(notes.length - 1);
    }
  };

  const handleEditNoteEnter = async (e, index) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      const updatedNote = e.target.value.trim();
      if (updatedNote) {
        const updatedNotes = notes.map((note, i) =>
          i === index ? updatedNote : note
        );
        setNotes(updatedNotes);
        setEditingIndex(null);
        await updateUserNotes(updatedNotes);
      } else {
        await handleDeleteNote(index, true);
      }
      mainInputRef.current?.focus();
    }
  };

  const handleEditNoteKeyDown = async (e, index) => {
    if (
      (e.key === 'Backspace' || e.key === 'Delete') &&
      e.target.value.trim() === ''
    ) {
      e.preventDefault();
      await handleDeleteNote(index, true);
    }
  };

  const handleDeleteNote = async (index, moveToPrevious = false) => {
    const updatedNotes = notes.filter((_, i) => i !== index);
    setNotes(updatedNotes);

    if (moveToPrevious && index > 0) {
      setEditingIndex(index - 1);
    } else {
      setEditingIndex(null);
    }

    setError('');
    await updateUserNotes(updatedNotes);
  };

  // Handler for clicking on a note to edit
  const handleNoteClick = (index) => {
    setEditingIndex(index); // Enter edit mode for the clicked note
  };

  // Automatically scroll to the bottom when a new note is added
  useEffect(() => {
    if (notesContainerRef.current) {
      notesContainerRef.current.scrollTop =
        notesContainerRef.current.scrollHeight;
    }
  }, [notes]);

  return (
    <div style={{ marginTop: '20px', textAlign: 'left', width: '100%' }}>
      <Text type="secondary" style={{ marginLeft: '5px' }}>
        {t('session_recorder_notepad.title')}
      </Text>
      <div
        style={{
          border: isContainerFocused ? '3px solid black' : '1px solid #d9d9d9',
          borderRadius: '10px',
          padding: '10px',
          minHeight: '30px',
          marginTop: '5px',
          marginBottom: '10px',
          textAlign: 'left',
        }}
      >
        {/* Scrollable List */}
        <div
          ref={notesContainerRef}
          style={{
            maxHeight: '100px',
            overflowY: 'auto',
          }}
        >
          {notes.length > 0 && (
            <List
              dataSource={notes}
              split={false}
              renderItem={(note, index) => (
                <List.Item
                  key={index}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '2px 0',
                  }}
                >
                  {editingIndex === index ? (
                    <Input
                      autoFocus
                      defaultValue={note}
                      onBlur={() => {
                        setEditingIndex(null);
                        handleBlur();
                      }}
                      onFocus={handleFocus}
                      onKeyPress={(e) => handleEditNoteEnter(e, index)}
                      onKeyDown={(e) => handleEditNoteKeyDown(e, index)}
                      style={{
                        width: '100%',
                        border: 'none',
                        boxShadow: 'none',
                      }}
                    />
                  ) : (
                    <span
                      onClick={() => handleNoteClick(index)}
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        backgroundColor: '#f0fdf4',
                        padding: '2px 6px',
                        borderRadius: '4px',
                        cursor: 'pointer', // Change cursor to pointer on hover
                      }}
                    >
                      {note}
                    </span>
                  )}
                </List.Item>
              )}
            />
          )}
        </div>
        <Input.TextArea
          placeholder={
            notes.length === 0
              ? t('session_recorder_notepad.placeholder')
              : ''
          }
          autoSize={{ minRows: 1, maxRows: 3 }}
          value={currentNote}
          onChange={(e) => setCurrentNote(e.target.value)}
          onKeyPress={handleAddNote}
          onKeyDown={handleMainInputKeyDown}
          ref={mainInputRef}
          onFocus={handleFocus}
          onBlur={handleBlur}
          style={{
            fontSize: '16px',
            border: 'none', // No border for the input field
            boxShadow: 'none',
            resize: 'none',
            marginTop: notes.length > 0 ? '2px' : '0',
          }}
        />
        {/* Error message */}
        {error && (
          <Text
            type="danger"
            style={{
              display: 'block',
              marginTop: '8px',
              fontSize: '12px',
            }}
          >
            {error}
          </Text>
        )}
      </div>
      <Text type="secondary" style={hintTextStyle}>
        {t('session_recorder_notepad.submit_hint')}
      </Text>
    </div>
  );
};

export default SessionRecorderNotepad;
