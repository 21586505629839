import React from 'react';
import Home from './pages/Home';
import Settings from './pages/Settings';
import Sessions from './pages/Sessions';
import Calls from './pages/Calls';
import SessionDetails from './pages/SessionDetails';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import EmailConfirmation from './pages/EmailConfirmation';
import ResetPasswordRequest from './pages/ResetPasswordRequest';
import ResetPasswordForm from "./pages/ResetPasswordForm";
import NotFoundPage from './pages/NotFoundPage';
import GoogleAuthCallback from './pages/GoogleAuthCallback';
import QueryDetails from './pages/QueryDetails';
import QueryNew from './pages/QueryNew';
import SharedQuery from './pages/SharedQuery';
import PublicQueryDetails from './pages/PublicQueryDetails';
import PublicQueries from './pages/PublicQueries';
import QueryFromUrl from './components/query/QueryFromUrl';
import Cases from './pages/Cases';
import CaseDetails from './pages/CaseDetails';
import CallDetails from './pages/CallDetails';
import LegalDocument from "./components/LegalDocument";

export const protectedRoutes = [
  {
    path: "/",
    element: <Home/>,
  },
  {
    path: "/settings",
    element: <Settings/>
  },
  {
    path: "/sessions",
    element: <Sessions/>
  },
  {
    path: "/sessions/:uuid",
    element: <SessionDetails/>,
    hideSidenav: true
  },
  {
    path: "/calls",
    element: <Calls/>
  },
  {
    path: "/calls/:uuid",
    element: <CallDetails/>,
    hideSidenav: true
  },
  {
    path: "/queries/new",
    element: <QueryNew/>
  },
  {
    path: "/queries/:uuid",
    element: <QueryDetails/>
  },
  {
    path: "/queries",
    element: <QueryFromUrl/>
  },
  {
    path: "/cases",
    element: <Cases/>
  },
  {
    path: "/cases/:uuid",
    element: <CaseDetails/>,
    hideSidenav: true
  },
];

export const publicRoutes = [
  {
    path: "/sign_in",
    element: <SignIn/>
  },
  {
    path: "/sign_up",
    element: <SignUp/>
  },
  {
    path: "/confirmation",
    element: <EmailConfirmation/>
  },
  {
    path: "/reset_password",
    element: <ResetPasswordRequest/>
  },
  {
    path: "/password/edit",
    element: <ResetPasswordForm/>
  },
  {
    path: "/auth/callback",
    element: <GoogleAuthCallback/>,
    layout: "blank"
  },
  // Public query routes
  {
    path: "/q/share/:share_token",
    element: <SharedQuery/>
  },
  {
    path: "/q/:slug/:uuid",
    element: <PublicQueryDetails/>
  },
  {
    path: "/q/queries",
    element: <PublicQueries/>
  },
  // Legal documents
  {
    path: "/terms_en",
    element: <LegalDocument fileName="terms_en.md"/>
  },
  {
    path: "/terms_es",
    element: <LegalDocument fileName="terms_es.md"/>
  },
  {
    path: "/privacy_en",
    element: <LegalDocument fileName="privacy_en.md"/>
  },
  {
    path: "/privacy_es",
    element: <LegalDocument fileName="privacy_es.md"/>
  },
  {
    path: "/usage_en",
    element: <LegalDocument fileName="usage_en.md"/>
  },
  {
    path: "/usage_es",
    element: <LegalDocument fileName="usage_es.md"/>
  },
  // 404 route
  {
    path: "*",
    element: <NotFoundPage/>
  }
];