import React from 'react';
import { Typography, Space, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import FeatureBenefits from "../FeatureBenefits";
import NewCaseButton from "./NewCaseButton";

const { Title, Paragraph } = Typography;

const cardStyle = {
  width: '100%',
  textAlign: 'center',
  paddingBottom: '30px',
};

const spaceStyle = {
  width: '100%',
};

const CaseEmptyState = ({ showModal }) => {
  const { t } = useTranslation();

  return (
    <Card
      style={cardStyle}
      data-testid="case-empty-state-container"
    >
      <Space direction="vertical" size="large" style={spaceStyle}>
        <Title level={3} style={{marginTop: '20px'}}>{t('case_empty_state.title')}</Title>
        <FeatureBenefits featureKey='case_empty_state' />
        <NewCaseButton />
      </Space>
    </Card>
  );
};

export default CaseEmptyState;
