import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spin, Modal, Space, Typography } from "antd";
import { AudioOutlined } from "@ant-design/icons";
import ToggleRecordingButton from "./ToggleRecordingButton";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import AudioWave from "../AudioWave";
import createCase from "../../utils/case/createCase";
import {handleError} from "../../utils/errorHandling";

const { Text } = Typography;

const CaseNewAudio = ({ isVisible, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const supportedAudioFormats = require('../../utils/supportedAudioFormats').default;
  const audioMimeType = supportedAudioFormats.filter(MediaRecorder.isTypeSupported)[0];

  const [isRecording, setIsRecording] = useState(false);
  const [isUploading, setIsUploading] = useState(false); // Indicates upload in progress
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioStream, setAudioStream] = useState(null);

  // Called when MediaRecorder fully stops
  const handleRecorderStop = async (recorder, chunks) => {
    console.log("Stopping case dictation recording...");

    // If the recorder was flagged for discard
    if (recorder.shouldDiscard) {
      console.log("Recording discarded.");
      return;
    }

    // Otherwise, we process the upload
    const blob = new Blob(chunks, { type: audioMimeType });
    console.log("Uploading case audio...");
    setIsUploading(true); // Start "Processing" mode

    try {
      const response = await createCase({ audio: blob });
      if (response && response.getUUID()) {
        navigate(`/cases/${response.getUUID()}`);
        onClose();
      } else {
        throw new Error("Failed to create case");
      }
    } catch (error) {
      console.error("Error uploading case:", error);
      toast.error(t("case_new.errors.upload_failure"));
    } finally {
      setIsUploading(false); // End "Processing" mode (if the user reopens or for future recordings)
    }
  };

  // Start recording
  const startRecording = async () => {
    try {
      console.log("Starting case audio recording...");
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const options = { mimeType: audioMimeType };
      console.log(`MediaRecorder: using ${audioMimeType} format for audio.`);
      const recorder = new MediaRecorder(stream, options);
      const chunks = [];

      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data);
        }
      };

      // Define onstop once, referencing recorder.shouldDiscard
      recorder.onstop = () => handleRecorderStop(recorder, chunks);

      recorder.start();
      setMediaRecorder(recorder);
      setAudioStream(stream);
      setIsRecording(true);

      // Automatically stop after 5 minutes, defaulting to upload
      setTimeout(() => {
        if (recorder.state === "recording") {
          stopRecording(false); // auto-stop without discarding
        }
      }, 5 * 60 * 1000);
    } catch (error) {
      handleError(t("case_new.errors.no_mic"))
    }
  };

  // Stop recording, optionally discarding
  const stopRecording = (shouldDiscard) => {
    if (isUploading) return; // Do nothing if already uploading

    if (mediaRecorder) {
      mediaRecorder.shouldDiscard = shouldDiscard;
      mediaRecorder.stop(); // Triggers onstop callback
      setIsRecording(false);
    }

    if (audioStream) {
      audioStream.getTracks().forEach((track) => track.stop());
      setAudioStream(null);
    }
  };

  // Close button logic
  const handleModalClose = () => {
    // If uploading, block closure
    if (isUploading) return;

    if (isRecording) {
      Modal.confirm({
        title: t("case_new.fields.confirm_title"),
        content: t("case_new.fields.confirm_message"),
        okText: t("case_new.fields.confirm_close"),
        okType: 'danger',
        cancelText: t("case_new.fields.cancel_close"),
        onOk: () => {
          stopRecording(true); // Discard
          onClose();
        },
      });
    } else {
      onClose();
    }
  };

  return (
    <Modal
      title={
        <div style={{ marginTop: "5px", marginBottom: "20px" }}>
          {isRecording && <AudioOutlined className="iconSpacing" />}
          {isRecording && t("case_new.fields.title_recording_in_progress") }
          {isUploading && t("case_new.fields.title_processing") }
          {!isRecording && !isUploading && t("case_new.title") }
        </div>
      }
      open={isVisible}
      onCancel={handleModalClose}
      footer={null}
      maskClosable={false} // Prevent closing by clicking outside
      closable={!isUploading} // Hide close button if uploading is in progress
      width={isRecording || isUploading ? 650 : 500}
      data-testid="new-case-modal-audio"
    >
      <Space direction="vertical" size="large" style={{ width: "100%", textAlign: "center" }}>
        {!isRecording && !isUploading && (
          <div>
            <Text type="secondary">
              {t("case_new.fields.recording_hint")}
            </Text>
          </div>
        )}
        {isUploading && (
          <div>
            <Text type="secondary">
              {t("case_new.fields.processing_hint")}
            </Text>
            <div style={{ marginTop: '20px', marginBottom: '20px' }}>
              <Spin />
            </div>
          </div>
        )}
        {isRecording && (
          <div>
            <Text type="secondary" italic>
              {t("case_new.fields.record_example")}
            </Text>
            <div style={{ marginTop: "40px", marginBottom: "0px" }}>
              <AudioWave stream={audioStream} isRecording={isRecording} />
            </div>
          </div>
        )}
        {!isUploading && (
          <div style={{ marginTop: "20px"}}>
            <ToggleRecordingButton
              isRecording={isRecording}
              isUploading={isUploading}
              onStart={() => startRecording()}
              onStop={() => stopRecording(false)}
            />
          </div>
        )}
      </Space>
    </Modal>
  );
};

export default CaseNewAudio;