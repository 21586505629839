import React from 'react';
import { Card, Typography, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const cardStyle = {
  maxWidth: '1000px',
};

const textContainerStyle = {
  textAlign: 'center',
  marginTop: '16px',
};

const secondaryTextStyle = {
  marginTop: '8px',
  display: 'block',
};

const SessionLoadingCard = ({ note_type_slug = 'general_soap' }) => {
  const { t } = useTranslation();

  return (
    <Card style={cardStyle}>
      <div style={textContainerStyle}>
        <Text>
          {t('session_details.fields.generating')}
          {t(`session_details.note_types.${note_type_slug}`)}
          ...
        </Text>
      </div>
      <div data-testid='loading-skeleton'>
        <Skeleton active/>
      </div>
    </Card>
);
};

export default SessionLoadingCard;
