import authRequest from '../../utils/authRequest';
import CallPresenter from '../../presenters/CallPresenter';

const fetchCall = async (uuid) => {
  try {
    const response = await authRequest(`/calls/${uuid}`, { method: 'GET' });
    if (response.status !== 200) {
      throw new Error('Fetching call failed');
    }
    return new CallPresenter(response.data);
  } catch (error) {
    console.error('Fetching call failed:', error);
    throw error;
  }
};

export default fetchCall;
