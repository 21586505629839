import { Device } from '@twilio/voice-sdk';
import { toast } from 'react-hot-toast';

const connectCall = async ({ twilioToken, callUUID, parsedNumber }) => {
  const device = new Device(twilioToken);

  // Handle errors directly
  device.on('error', () => {
    toast.error('Device error occurred.');
  });

  await device.register();
  // Short pause to ensure device is fully registered
  await new Promise((resolve) => setTimeout(resolve, 1000));

  const call = await device.connect({
    params: {
      To: parsedNumber.format('E.164'),
      CallUUID: callUUID,
    },
  });

  return call;
};

export default connectCall;
