
import React from 'react';
import { Card, Descriptions, Divider, Typography } from 'antd';

import { useTranslation } from 'react-i18next';
import CaseUpdatedTag from './CaseUpdatedTag';
import CaseChangesPresenter from '../../presenters/CaseChangesPresenter';

const { Text, Paragraph } = Typography;

const CaseSummaryCard = ({ casePresenter, changeset }) => {
  const { t } = useTranslation();
  const changesPresenter = new CaseChangesPresenter(casePresenter, changeset);

  const renderValue = (fieldName) => {
    const value = changesPresenter.getFieldValue(fieldName);
    const isUpdated = changesPresenter.isUpdated(fieldName);

    return (
      <span>
        {value && value !== 'N/A' ? <Text>{value}</Text> : <Text type="secondary">N/A</Text>}
        {isUpdated && <CaseUpdatedTag />}
      </span>
    );
  };

  if (!casePresenter) {
    return null;
  }

  return (
    <Card className="case-patient-info-card">
      <Descriptions
        column={{ xs: 2, sm: 2, md: 4, lg: 6, xl: 6 }}
        layout="horizontal"
      >
        <Descriptions.Item label={t('case_details.fields.sex')}>
          {renderValue('gender')}
        </Descriptions.Item>
        <Descriptions.Item label={t('case_details.fields.dob')}>
          {renderValue('dob')}
        </Descriptions.Item>
        <Descriptions.Item label={t('case_details.fields.age')}>
          {renderValue('age')}
        </Descriptions.Item>
        <Descriptions.Item label={t('case_details.fields.civil_status')}>
          {renderValue('civil_status')}
        </Descriptions.Item>
        <Descriptions.Item label={t('case_details.fields.occupation')} span={2}>
          {renderValue('occupation')}
        </Descriptions.Item>
        <Descriptions.Item label={t('case_details.fields.location')}>
          {renderValue('location')}
        </Descriptions.Item>
        <Descriptions.Item label={t('case_details.fields.religion')}>
          {renderValue('religion')}
        </Descriptions.Item>
        <Descriptions.Item label={t('case_details.fields.weight')}>
          {renderValue('weight')}
        </Descriptions.Item>
        <Descriptions.Item label={t('case_details.fields.height')}>
          {renderValue('height')}
        </Descriptions.Item>
        <Descriptions.Item label={t('case_details.fields.bmi')}>
          {renderValue('bmi')}
        </Descriptions.Item>
        <Descriptions.Item label={t('case_details.fields.blood_type')}>
          {renderValue('blood_type')}
        </Descriptions.Item>
      </Descriptions>
      <Divider />
      <Text type="secondary" data-testid="summary-label">
        {t('case_details.fields.summary')}
      </Text>
      <Paragraph>
        {casePresenter.getSummary() || <Text type="secondary">N/A</Text>}
      </Paragraph>
    </Card>
  );
};

export default CaseSummaryCard;
