import React, { useState } from 'react';
import { Dropdown, Button } from 'antd';
import { AudioTwoTone, EditTwoTone, DownOutlined } from "@ant-design/icons";
import { useTranslation } from 'react-i18next';
import CaseNew from '../case/CaseNew';
import CaseNewAudio from '../case/CaseNewAudio';

const NewCaseButton = ({ onCaseCreated }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [inputType, setInputType] = useState(null); // Tracks selected input type
  const { t } = useTranslation();

  const handleMenuClick = ({ key }) => {
    setInputType(key); // Set the input type (text or audio)
    setIsModalVisible(true); // Show the modal
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setInputType(null); // Reset input type after modal closes
  };

  const items = [
    {
      key: 'text',
      label: (
        <div data-testid="dropdown-option-text">
          <EditTwoTone style={{ marginRight: '5px', fontSize: '16px', }} />
          {t('case_new.fields.text_input')}
        </div>
      ),
    },
    {
      key: 'audio',
      label: (
        <div data-testid="dropdown-option-audio">
          <AudioTwoTone style={{ marginRight: '5px', fontSize: '16px', }} />
          {t('case_new.fields.audio_input')}
        </div>
      ),
    },
  ];

  return (
    <>
      <Dropdown
        menu={{
          items,
          onClick: handleMenuClick,
        }}
        trigger={['click']}
      >
        <Button type="primary" size="large" shape="round" data-testid="new-case-button">
          {t('cases.new_case')} <DownOutlined />
        </Button>
      </Dropdown>

      {/* CaseNew Modal for Text Input */}
      {isModalVisible && inputType === 'text' && (
        <CaseNew
          isVisible={isModalVisible}
          onClose={handleModalClose}
          inputType="text"
        />
      )}

      {/* CaseNewAudio for Dictating a Case */}
      {isModalVisible && inputType === 'audio' && (
        <CaseNewAudio
          isVisible={isModalVisible}
          onClose={handleModalClose}
          onUpload={(audioBlob) => {
            console.log('Uploading case audio...');
            setIsModalVisible(false); // Close the modal after handling audio
          }}
        />
      )}
    </>
  );
};

export default NewCaseButton;