import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Space, Typography, Layout, Row, Col } from 'antd';
import BackLink from '../components/BackLink';
import SessionDetailsLoadingFailedInfo from '../components/session/SessionDetailsLoadingFailedInfo';
import fetchSession from '../utils/session/fetchSession';
import { handleError } from '../utils/errorHandling';
import SessionPresenter from '../presenters/SessionPresenter';
import NoteDetailsTabs from '../components/session/NoteDetailsTabs';
import SessionLoadingCard from "../components/session/SessionLoadingCard";
import LoadingCard from '../components/LoadingCard';

const { Title } = Typography;
const { Content } = Layout;

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const pollSession = async (uuid) => {
  const startTime = Date.now();
  const timeout = 300000; // 5 minutes in milliseconds

  while (Date.now() - startTime <= timeout) {
    console.log(`pollSession: fetching session uuid ${uuid}`);
    const receivedSession = await fetchSession(uuid);

    if (receivedSession.isReady()) {
      console.log(`pollSession: received session has necessary data, stopping polling`);
      return receivedSession;
    }

    await sleep(2000);
  }

  throw new Error('Polling timeout reached');
};

function SessionDetails() {
  const { t } = useTranslation();
  const { uuid } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [session, setSession] = useState(new SessionPresenter(null));
  const [sessionLoading, setSessionLoading] = useState(true);
  const [loadingFailed, setLoadingFailed] = useState(false);
  const query = new URLSearchParams(location.search);
  const isGenerating = query.get('generating') !== null;

  useEffect(() => {
    let isMounted = true;

    const fetchSessionDetails = async () => {
      try {
        setSessionLoading(true);

        // Fetch initial session data
        const initialSession = await fetchSession(uuid);
        if (!isMounted) return;

        setSession(initialSession);
        setSessionLoading(false);

        // If generating, start polling
        if (isGenerating) {
          const updatedSession = await pollSession(uuid);
          setSession(updatedSession);
          if (!isMounted) return;

          // Remove the `generating` parameter after polling
          const updatedSearchParams = new URLSearchParams(location.search);
          updatedSearchParams.delete('generating');
          navigate(`/sessions/${uuid}?${updatedSearchParams.toString()}`, { replace: true });
        }
      } catch (error) {
        handleError(error);
        setLoadingFailed(true);
        setSessionLoading(false);
      }
    };

    fetchSessionDetails();

    // Cleanup function to prevent updates after unmount
    return () => {
      isMounted = false;
    };
  }, [uuid, isGenerating, location.search, navigate]);

  if (sessionLoading) {
    return <LoadingCard/>;
  }

  if (!loadingFailed && !session.isReady()) {
    return (
      <Content className='page'>
        <SessionLoadingCard note_type_slug={session.getLastDoctorNoteType()} />
      </Content>
    );
  }

  if (loadingFailed) {
    handleError(t('session_details.error'));
    navigate('/sessions');
    return null;
  }

  return (
    <Content className='page'>
      <Space direction='vertical' size={16} style={{ width: '100%' }}>
        <Row align="middle" gutter={[16, 16]} style={{ marginBottom: '16px' }}>
          <Col flex="none">
            <BackLink to='/sessions' label={t('navigation_header.sessions')} />
          </Col>
          <Col flex="auto">
            <Title level={4} style={{ margin: 0, marginLeft: '10px' }}>
              {session.getTitle()}
            </Title>
          </Col>
        </Row>
        <div style={{ maxWidth: session.displayWidgetsColumn() ? '1000px' : '800px' }}>
          {loadingFailed && !session.isReady() && <SessionDetailsLoadingFailedInfo />}
          {session.displayNote() && <NoteDetailsTabs source={session} />}
          {session.displayNote() &&
            <div style={{ marginTop: '16px' }}>
              <BackLink to='/sessions' label={t('navigation_header.sessions')} />
            </div>
          }
        </div>
      </Space>
    </Content>
  );
}

export default SessionDetails;
