import React, { useState } from 'react';
import { Modal, Form, Select, Input, Button, ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import sendSupportRequest from '../utils/sendSupportRequest';

const { TextArea } = Input;
const { Option } = Select;

const SupportModal = ({ visible, onClose }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm(); // Ant Design Form instance for validation

  const handleSubmit = async () => {
    try {
      await form.validateFields(); // Validate all fields
      const values = form.getFieldsValue(); // Retrieve form values
      await sendSupportRequest(values.subject, values.body);
      toast.success(t('support.fields.send_ok'));
      onClose();
      form.resetFields();
    } catch (error) {
      toast.error(t('support.fields.send_failed'));
    }
  };

  return (
    <Modal
      title={t('support.title')}
      open={visible}
      onCancel={onClose}
      footer={null} // Custom footer for buttons
    >
      <Form
        layout="vertical"
        form={form} // Link the form instance
        onFinish={handleSubmit} // Handle form submission
        requiredMark={false}
      >
        <Form.Item
          name="subject"
          label={t('support.fields.subject')}
          rules={[
            { required: true, message: t('support.fields.subject_required') },
          ]}
        >
          <Select
            placeholder={t('support.fields.select_subject')}
            style={{ fontSize: '16px' }} // Prevent mobile zoom
            data-testid="support-subject-select"
          >
            <Option value={t('support.subjects.answers')} style={{ fontSize: '16px' }}>{t('support.subjects.answers')}</Option>
            <Option value={t('support.subjects.patient_visits')} style={{ fontSize: '16px' }}>{t('support.subjects.patient_visits')}</Option>
            <Option value={t('support.subjects.cases')} style={{ fontSize: '16px' }}>{t('support.subjects.cases')}</Option>
            <Option value={t('support.subjects.calls')} style={{ fontSize: '16px' }}>{t('support.subjects.calls')}</Option>
            <Option value={t('support.subjects.account_issues')} style={{ fontSize: '16px' }}>{t('support.subjects.account_issues')}</Option>
            <Option value={t('support.subjects.other')} style={{ fontSize: '16px' }}>{t('support.subjects.other')}</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="body"
          label={t('support.fields.body')}
          rules={[
            { required: true, message: t('support.fields.body_required') },
          ]}
        >
          <TextArea
            placeholder={t('support.fields.enter_message')}
            rows={4}
            style={{ fontSize: '16px' }} // Prevent mobile zoom
            data-testid="support-body"
          />
        </Form.Item>
        <div style={{ textAlign: 'right', marginTop: '16px' }}>
          <Button
            onClick={onClose}
            style={{ marginRight: '8px' }}
          >
            {t('common.cancel')}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
          >
            {t('common.submit')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default SupportModal;